import React from 'react';

const Callout = ({
    title = false,
	message = false, 
	align = false, 
	classNames = false
}) => {
	const createClassList = () => {
        let classList = 'ca-callout';
        if(align) classList = classList + ' ca-callout--' + align;
        if (classNames) classList = classList + ' ' + classNames;
        return classList;
    }

    const titleElement = (title) ? (
        <span className='ca-callout__title'>{title}</span>
    ) : null;

    const messageElement = (message) ? (
        <span className='ca-callout__message'>{message}</span>
    ) : null;

    const iconElement = (
        <span className='ca-callout__icon'>
            <img src={require(`../../images/reminder-icon.svg`)} alt='reminder icon' />
        </span>
    )
	
	return (
		<div className={createClassList()}>
			{iconElement}
            <div className='ca-callout__content'>
                {titleElement}
                {messageElement}
            </div>
		</div>
	);
};

export default Callout;
